export const defaultLocation = "United States";
export const locationData = [
  {
    name: "Australia",
    code: "AU",
    id: "1000142",
    languages: [],
  },
  {
    name: "Austria",
    code: "AT",
    id: "1000683",
    languages: ["1001"],
  },
  {
    name: "Belgium",
    code: "BE",
    id: "1001001",
    languages: ["1010", "1002"],
  },
  {
    name: "Bulgaria",
    code: "BG",
    id: "1001445",
    languages: ["1020"],
  },
  {
    name: "Canada",
    code: "CA",
    id: "1001792",
    languages: [],
  },
  {
    name: "Croatia",
    code: "HR",
    id: "1007611",
    languages: ["1039"],
  },
  {
    name: "Cyprus",
    code: "CY",
    id: "1003698",
    languages: [],
  },
  {
    name: "Czech Republic",
    code: "CZ",
    id: "1003704",
    languages: ["1021"],
  },
  {
    name: "Denmark",
    code: "DK",
    id: "1005005",
    languages: ["1009"],
  },
  {
    name: "Estonia",
    code: "EE",
    id: "1005553",
    languages: ["1043"],
  },
  {
    name: "Finland",
    code: "FI",
    id: "1005573",
    languages: ["1011"],
  },
  {
    name: "France",
    code: "FR",
    id: "1005781",
    languages: ["1002"],
  },
  {
    name: "Germany",
    code: "DE",
    id: "1003853",
    languages: ["1001"],
  },
  {
    name: "Greece",
    code: "GR",
    id: "1007491",
    languages: ["1022"],
  },
  {
    name: "Hong Kong",
    code: "HK",
    id: "2344",
    languages: [],
  },
  {
    name: "Hungary",
    code: "HU",
    id: "1007623",
    languages: ["1024"],
  },
  {
    name: "Iceland",
    code: "IS",
    id: "1007951",
    languages: ["1026"],
  },
  {
    name: "India",
    code: "IN",
    id: "1007740",
    languages: [],
  },
  {
    name: "Indonesia",
    code: "ID",
    id: "1007696",
    languages: ["1025"],
  },
  {
    name: "Ireland",
    code: "IE",
    id: "1007834",
    languages: [],
  },
  {
    name: "Israel",
    code: "IL",
    id: "1007965",
    languages: ["1027"],
  },
  {
    name: "Italy",
    code: "IT",
    id: "1008021",
    languages: ["1004"],
  },
  {
    name: "Japan",
    code: "JP",
    id: "1009023",
    languages: ["1005"],
  },
  {
    name: "Latvia",
    code: "LV",
    id: "1009967",
    languages: ["1028"],
  },
  {
    name: "Lithuania",
    code: "LT",
    id: "1009935",
    languages: ["1029"],
  },
  {
    name: "Luxembourg",
    code: "LU",
    id: "1009944",
    languages: [],
  },
  {
    name: "Malta",
    code: "MT",
    id: "1010212",
    languages: [],
  },
  {
    name: "Mexico",
    code: "MX",
    id: "1010000",
    languages: [],
  },
  {
    name: "Netherlands",
    code: "NL",
    id: "1010304",
    languages: ["1010"],
  },
  {
    name: "New Zealand",
    code: "NZ",
    id: "1011036",
    languages: [],
  },
  {
    name: "Norway",
    code: "NO",
    id: "1010774",
    languages: ["1013"],
  },
  {
    name: "Poland",
    code: "PL",
    id: "1011195",
    languages: ["1030"],
  },
  {
    name: "Portugal",
    code: "PT",
    id: "1011705",
    languages: ["1014"],
  },
  {
    name: "Romania",
    code: "RO",
    id: "1011789",
    languages: ["1032"],
  },
  {
    name: "Russia",
    code: "RU",
    id: "1011852",
    languages: ["1031"],
  },
  {
    name: "Saudi Arabia",
    code: "SA",
    id: "1012088",
    languages: ["1019"],
  },
  {
    name: "Slovakia",
    code: "SK",
    id: "1012118",
    languages: ["1033"],
  },
  {
    name: "Slovenia",
    code: "SI",
    id: "1012150",
    languages: ["1034"],
  },
  {
    name: "South Africa",
    code: "ZA",
    id: "1028617",
    languages: [],
  },
  {
    name: "South Korea",
    code: "KR",
    id: "1009832",
    languages: ["1012"],
  },
  {
    name: "Spain",
    code: "ES",
    id: "1005402",
    languages: ["1003"],
  },
  {
    name: "Sweden",
    code: "SE",
    id: "1012182",
    languages: ["1015"],
  },
  {
    name: "Switzerland",
    code: "CH",
    id: "1002807",
    languages: [],
  },
  {
    name: "Thailand",
    code: "TH",
    id: "1012728",
    languages: ["1044"],
  },
  {
    name: "Turkey",
    code: "TR",
    id: "1012761",
    languages: ["1037"],
  },
  {
    name: "United Kingdom",
    code: "GB",
    id: "1002316",
    languages: [],
  },
  {
    name: "United States",
    code: "US",
    id: "1012873",
    languages: [],
  },
];
