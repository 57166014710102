export const cnUrl = "https://www.sora-video.ai";
export const enUrl = "https://www.sora-video.ai";

const siteConfig = {
  title: "Sora AI - Best AI Text To Video Generator",
  description:
    "Sora AI is the best AI text to video generator. Create stunning videos from text in minutes. No video editing skills required. Try it for free!",
  email: "info@sora-video.ai",
  logo: {
    url: "/logo.svg",
    alt: "Logo of Sora Video AI",
  },
  whiteLogo: {
    url: "/logo-white.png",
    alt: "Logo of Sora Video AI",
  },
  logoIcon: {
    url: "/logo-icon-only.png",
    alt: "Logo of Sora Video AI",
  },
  logoWithBlackText: {
    url: "/logo-with-black-text.svg",
    alt: "Logo of Sora Video AI",
  },
  favicon: "/favicon.png",
  openGraphImage: "/og.png",
  url: enUrl,
  locale: "en",
  alternateUrl: cnUrl,
  alternateHrefLang: "zh",
  name: "Sora Video AI",
  links: {
    twitter: "https://twitter.com/sora_ai",
    facebook: "https://www.facebook.com/profile.php?id=100095",
    youtube: "https://youtube.com",
    linkedin: "https://linkedin.com",
  },
};

export { siteConfig };
