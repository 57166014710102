import { LogoWithBlackText } from "components/logo";
import { AppBar, Toolbar } from "components/ui/app-bar";
import { Box } from "components/ui/box";
import { Button, IconButton, BlackButton } from "components/ui/button";
import React from "react";
import MenuIcon from "@mui/icons-material/Menu";
import { headerHeight, MainBackgroundWrapper } from "./shared";
import { useUser } from "hooks/use-user";
import { serverRoutes } from "client-server-shared/config/routes";
import { NextLink } from "components/next-link";
import { useTheme } from "@mui/material/styles";
import { Container } from "../marketing-pages/front-page/shared";
import { Typography } from "components/ui/Typography";
import { Collapse } from "components/ui/animation/collapse";
import { useTranslation } from "components/translate";
import Submenu from "./submenu";

const usePages = () => {
  const { t } = useTranslation("common");
  const pages = [
    /*
    {
      title: "Showcases",
      to: serverRoutes.showCases,
    },
    */
    {
      title: "Blogs",
      to: serverRoutes.blog,
    },
    /*
    {
      title: "Pricing",
      to: serverRoutes.pricing,
    },
    */
  ];
  return pages;
};

const usePagesV2 = () => {
  const { t } = useTranslation("common");
  const pages = [
    /*
    {
      title: "Showcases",
      to: serverRoutes.showCases,
    },
    */
    {
      title: "Blogs",
      to: serverRoutes.blog,
    },
    /*
    {
      title: "Pricing",
      to: serverRoutes.pricing,
    },
    */
  ];
  return pages;
};

interface HeaderLayoutContextValues {
  mobileDrawerOpen: boolean;
  toggleMobileDrawer: () => void;
  closeMobileDrawer: () => void;
}

const HeaderLayoutContext =
  React.createContext<HeaderLayoutContextValues | null>(null);

const useHeaderLayoutContext = () => React.useContext(HeaderLayoutContext)!;

const HeaderLayoutContextProvider = ({ children }) => {
  const [mobileDrawerOpen, setMobileDrawerOpen] = React.useState(false);
  const toggleMobileDrawer = React.useCallback(() => {
    setMobileDrawerOpen((previous) => !previous);
  }, []);
  const closeMobileDrawer = React.useCallback(() => {
    setMobileDrawerOpen(false);
  }, []);
  const values = {
    mobileDrawerOpen,
    toggleMobileDrawer,
    closeMobileDrawer,
  };
  return (
    <HeaderLayoutContext.Provider value={values}>
      {children}
    </HeaderLayoutContext.Provider>
  );
};

const LogoButtons = () => {
  const { closeMobileDrawer } = useHeaderLayoutContext();
  return (
    <>
      <IconButton
        component={NextLink}
        href="/"
        disableRipple
        onClick={closeMobileDrawer}
        sx={{
          display: { xs: "none", md: "flex" },
          mr: 3,
        }}
      >
        <LogoWithBlackText />
      </IconButton>
      <IconButton
        component={NextLink}
        href="/"
        disableRipple
        onClick={closeMobileDrawer}
        sx={{ display: { xs: "flex", md: "none" }, mr: 1 }}
      >
        <LogoWithBlackText height={30} width={120} />
      </IconButton>
    </>
  );
};

const MobileDrawer = () => {
  const theme = useTheme();
  const { toggleMobileDrawer, mobileDrawerOpen, closeMobileDrawer } =
    useHeaderLayoutContext();
  const pages = usePages();
  const user = useUser();
  const { t } = useTranslation("common");
  return (
    <Box
      aria-hidden={!mobileDrawerOpen ? "true" : "false"}
      id="menu-appbar"
      sx={{
        zIndex: 99999,
        width: "100%",
        background: "white",
        top: headerHeight - 8,
        left: 0,
        height: mobileDrawerOpen ? "100vh" : "auto",
        display: { xs: "flex", md: "none", lg: "none" },
        position: "absolute",
      }}
    >
      <Collapse
        in={mobileDrawerOpen}
        sx={{
          width: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            width: "100%",
            flexDirection: "column",
            alignItems: "flex-start",
            rowGap: "24px",
            padding: "12px 24px",
            marginTop: "30%",
          }}
        >
          {pages.map((page) => (
            <Button
              capitalize
              key={page.title}
              component={NextLink}
              href={page.to}
              onClick={closeMobileDrawer}
              sx={{
                display: "flex",
                textAlign: "center",
                justifyContent: "center",
                width: "100%",
                paddingBottom: "12px",
                borderRadius: 0,
              }}
            >
              <Typography
                variant="body2"
                sx={{
                  fontSize: "20px",
                  fontWeight: 500,
                }}
              >
                {page.title}
              </Typography>
            </Button>
          ))}
          {/*
           <Button
              capitalize
              component={NextLink}
              href={serverRoutes.register}
              onClick={closeMobileDrawer}
              sx={{
                display: "flex",
                textAlign: "center",
                justifyContent: "center",
                width: "100%",
                paddingBottom: "12px",
                borderRadius: 0,
              }}
            >
              <Typography
                variant="body2"
                sx={{
                  fontSize: "20px",
                  fontWeight: 500,
                }}
              >
                Get Started
              </Typography>
            </Button>
          */}
        </Box>
      </Collapse>
    </Box>
  );
};

const MenuControl = () => {
  const { toggleMobileDrawer, mobileDrawerOpen } = useHeaderLayoutContext();

  return (
    <IconButton
      size="large"
      sx={{
        borderRadius: "5px",
        marginLeft: "auto",
        display: { xs: "flex", md: "none", lg: "none", position: "relative" },
      }}
      aria-label="Open menu"
      aria-controls="menu-appbar"
      aria-haspopup="true"
      onClick={toggleMobileDrawer}
      color="inherit"
    >
      <MenuIcon
        sx={{
          fill: "rgb(33, 43, 54)",
        }}
      />
    </IconButton>
  );
};

const Header = () => {
  const user = useUser();
  const pages = usePages();
  const v2Pages = usePagesV2();
  const { t } = useTranslation("common");

  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null
  );
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null
  );

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <HeaderLayoutContextProvider>
      <MainBackgroundWrapper
        sx={{
          boxShadow: "none",
        }}
        component={AppBar}
        position="sticky"
      >
        <Container
          maxWidth="xl"
          sx={{
            /*
            marginTop: "24px",
            borderRadius: "22px",
            boxShadow:
              "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",
              */
            height: {
              xs: "auto",
              sm: "auto",
              md: "auto",
              lg: "72px",
              xl: "72px",
            },
            padding: {
              xs: "2px 16px",
              sm: "2px 16px",
              md: "2px 16px",
              lg: "2px 16px",
              xl: "2px 0",
            },
          }}
        >
          <Toolbar
            disableGutters
            sx={
              {
                //    padding: "0 20px",
              }
            }
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <LogoButtons />
              <Box
                component="nav"
                sx={{
                  flexGrow: 1,
                  display: { xs: "none", md: "flex" },
                  columnGap: "10px",
                }}
              >
                {v2Pages.map((page) => {
                  if (page.items && page.items.length > 0) {
                    return <Submenu {...page} index={page.title} />;
                  }
                  return (
                    <Button
                      capitalize
                      key={page.title}
                      component={NextLink}
                      href={page.to}
                      onClick={handleCloseNavMenu}
                      sx={{
                        my: 2,
                        textAlign: "center",
                        display: "block",
                        fontSize: "14px",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {page.title}
                    </Button>
                  );
                })}
              </Box>
            </Box>
            <Box
              sx={{
                flexGrow: 0,
                marginLeft: "auto",
                display: {
                  xs: "none",
                  md: "flex",
                },
                alignItems: "center",
                columnGap: "12px",
              }}
            >
              {/*
              <BlackButton
                  component={NextLink}
                  href={serverRoutes.register}
                  sx={{
                    display: "flex",
                    color: "text.white",
                    //  border: "3px solid #B354EA",
                    fontSize: "14px",
                    padding: "6px 6px 6px 8px",
                    fontWeight: 600,
                  }}
                >
                  Get Started
                </BlackButton>
              */}
            </Box>
            <MenuControl />
            {/* 
                <Box
                sx={{
                  flexGrow: 1,
                  marginLeft: "auto",
                  display: { xs: "flex", md: "none" },
                }}
              >
                <MenuControl />
              </Box>
        */}
          </Toolbar>
          <MobileDrawer />
        </Container>
      </MainBackgroundWrapper>
    </HeaderLayoutContextProvider>
  );
};

export default Header;
