import type { BulkGenerateJobConfigPayload } from "./types/workflow";
export const locale = process.env.NEXT_PUBLIC_LOCALE;

export const isCn = () => locale === "cn";

let lang = "English";

if (isCn()) {
  lang = "Chinese";
}

export const adminUserIds = [
  "f2f98d5e-63c4-412b-92cd-f82429517add",
  "e5afbc74-c7dc-48ce-a032-91ae0bd010a8",
  "9223b844-e3a0-42b6-9938-0ecffedbade2",
  "ad123253-6dc1-41bd-b2f4-1d944eb68760",
  "e5763b7e-00a9-487e-8bc6-ed135b48f60b",
  "bc58f479-a905-41f8-b1fa-d56dc13a8e8d",
  "18d32214-afe4-4bec-8652-8e6d1b062e19",
  "e8ea8e1f-482a-4fb1-b14a-8a0e53a999eb",
];

export { lang };

export const languagesExampleByLanguage = {
  English: "English",
  Arabic: "العربية",
  Bengali: "বাংলা",
  Bulgarian: "български",
  Chinese: "中文",
  Croatian: "hrvatski",
  Czech: "čeština",
  Danish: "dansk",
  Dutch: "Nederlands",
  Estonian: "eesti keel",
  Finnish: "suomi",
  French: "français",
  Georgian: "ქართული",
  German: "Deutsch",
  Greek: "Ελληνικά",
  Hebrew: "עִבְרִית‎",
  Hindi: "हिन्दी",
  Hungarian: "Magyar",
  Indonesian: "Bahasa Indonesia",
  Italian: "Italiano",
  Japanese: "日本語",
  Korean: "한국어",
  Latvian: "Latviešu valoda",
  Lithuanian: "Lietuvių kalba",
  Malay: "Bahasa Melayu",
  Maltese: "Malti",
  Norwegian: "norsk (bokmål)",
  Polish: "polski",
  Portuguese: "português",
  Romanian: "română",
  Russian: "русский",
  Serbian: "српски језик",
  Slovak: "slovenčina",
  Slovenian: "slovenščina",
  Spanish: "español",
  Swedish: "svenska",
  Tamil: "தமிழ்",
  Thai: "ไทย",
  Turkish: "Türkçe",
  Ukrainian: "українська мова",
  Vietnamese: "Tiếng Việt",
};

export const getTypographicRuleByLanguage = (language?: string) => {
  const rules = {
    French: `, Suivez ces règles typographiques spécifiques :
1. Capitalisation dans les titres : capitalisez uniquement la première lettre du premier mot. Par exemple, 'Conception et Types d'Escaliers Suspendus' doit être modifié en 'Conception et types d'escaliers suspendus'.
2. Majuscule après un deux-points : après un deux-points (':'), la première lettre du mot suivant doit être en minuscule, sauf s'il s'agit d'un nom propre ou si cela introduit une citation, une liste ou un discours direct. Par exemple, 'Escalier flottant: Un type' doit être changé en 'Escalier flottant : un type'.
3. Espaces insécables : incluez un espace insécable avant et après les points-virgules, les deux-points, les tirets, les points d'interrogation et les points d'exclamation.
4. Guillemets français : utilisez les guillemets français (« et ») avec un espace avant et après chacun. Pour les citations à l'intérieur des citations, utilisez les guillemets de style anglais.\n`,
  };
  return rules[language || ""] || "";
};

export const languageToLocale = {
  English: "en-US",
  Arabic: "ar-SA",
  Bengali: "bn-BD",
  Bulgarian: "bg-BG",
  Chinese: "zh-CN",
  Croatian: "hr-HR",
  Czech: "cs-CZ",
  Danish: "da-DK",
  Dutch: "nl-NL",
  Estonian: "et-EE",
  Finnish: "fi-FI",
  French: "fr-FR",
  Georgian: "ka-GE",
  German: "de-DE",
  Greek: "el-GR",
  Hebrew: "he-IL",
  Hindi: "hi-IN",
  Hungarian: "hu-HU",
  Indonesian: "id-ID",
  Italian: "it-IT",
  Japanese: "ja-JP",
  Korean: "ko-KR",
  Latvian: "lv-LV",
  Lithuanian: "lt-LT",
  Malay: "ms-MY",
  Maltese: "mt-MT",
  Norwegian: "nb-NO",
  Polish: "pl-PL",
  Portuguese: "pt-PT", // pt-BR for Brazilian Portuguese
  Romanian: "ro-RO",
  Russian: "ru-RU",
  Serbian: "sr-RS",
  Slovak: "sk-SK",
  Slovenian: "sl-SI",
  Spanish: "es-ES", // es-MX for Mexican Spanish
  Swedish: "sv-SE",
  Tamil: "ta-IN",
  Thai: "th-TH",
  Turkish: "tr-TR",
  Ukrainian: "uk-UA",
  Vietnamese: "vi-VN",
};

export const languageToLocalePlain = {
  English: "en",
  Arabic: "ar",
  Bengali: "bn",
  Bulgarian: "bg",
  Chinese: "zh",
  Croatian: "hr",
  Czech: "cs",
  Danish: "da",
  Dutch: "nl",
  Estonian: "et-EE",
  Finnish: "fi",
  French: "fr",
  Georgian: "ka",
  German: "de",
  Greek: "el",
  Hebrew: "he",
  Hindi: "hi",
  Hungarian: "hu",
  Indonesian: "id",
  Italian: "it",
  Japanese: "ja",
  Korean: "ko",
  Latvian: "lv",
  Lithuanian: "lt",
  Malay: "ms",
  Maltese: "mt",
  Norwegian: "nb",
  Polish: "pl-PL",
  Portuguese: "pt-PT",
  Romanian: "ro",
  Russian: "ru",
  Serbian: "sr",
  Slovak: "sk",
  Slovenian: "sl",
  Spanish: "es",
  Swedish: "sv",
  Tamil: "ta",
  Thai: "th",
  Turkish: "tr",
  Ukrainian: "uk",
  Vietnamese: "vi",
};

interface LanguageOption {
  value: string;
  label: string;
  example?: string;
}

let languageOptions: LanguageOption[] = [
  "English",
  "Arabic",
  "Bengali",
  "Bulgarian",
  "Chinese",
  "Croatian",
  "Czech",
  "Danish",
  "Dutch",
  "Estonian",
  "Finnish",
  "French",
  "Georgian",
  "German",
  "Greek",
  "Hebrew",
  "Hindi",
  "Hungarian",
  "Indonesian",
  "Italian",
  "Japanese",
  "Korean",
  "Latvian",
  "Lithuanian",
  "Malay",
  "Maltese",
  "Norwegian",
  "Polish",
  "Portuguese",
  "Romanian",
  "Russian",
  "Serbian",
  "Slovak",
  "Slovenian",
  "Spanish",
  "Swedish",
  "Tamil",
  "Thai",
  "Turkish",
  "Ukrainian",
  "Vietnamese",
].map((item) => {
  return {
    value: item,
    label: item,
    example: languagesExampleByLanguage[item],
  };
});

if (isCn()) {
  languageOptions = [
    {
      label: "中文",
      value: "Chinese",
    },
    {
      label: "英语",
      value: "English",
    },
    {
      label: "阿拉伯语",
      value: "Arabic",
    },
    {
      label: "孟加拉语",
      value: "Bengali",
    },
    {
      label: "保加利亚语",
      value: "Bulgarian",
    },
    {
      label: "克罗地亚语",
      value: "Croatian",
    },
    {
      label: "捷克语",
      value: "Czech",
    },
    {
      label: "丹麦语",
      value: "Danish",
    },
    {
      label: "荷兰语",
      value: "Dutch",
    },
    {
      label: "爱沙尼亚语",
      value: "Estonian",
    },
    {
      label: "芬兰语",
      value: "Finnish",
    },
    {
      label: "法语",
      value: "French",
    },
    {
      label: "格鲁吉亚语",
      value: "Georgian",
    },
    {
      label: "德语",
      value: "German",
    },
    {
      label: "希腊语",
      value: "Greek",
    },
    {
      label: "希伯来语",
      value: "Hebrew",
    },
    {
      label: "印地语",
      value: "Hindi",
    },
    {
      label: "匈牙利语",
      value: "Hungarian",
    },
    {
      label: "印尼语",
      value: "Indonesian",
    },
    {
      label: "意大利语",
      value: "Italian",
    },
    {
      label: "日语",
      value: "Japanese",
    },
    {
      label: "韩语",
      value: "Korean",
    },
    {
      label: "拉脱维亚语",
      value: "Latvian",
    },
    {
      label: "立陶宛语",
      value: "Lithuanian",
    },
    {
      label: "马来语",
      value: "Malay",
    },
    {
      label: "马耳他语",
      value: "Maltese",
    },
    {
      label: "挪威语",
      value: "Norwegian",
    },
    {
      label: "波兰语",
      value: "Polish",
    },
    {
      label: "葡萄牙语",
      value: "Portuguese",
    },
    {
      label: "罗马尼亚语",
      value: "Romanian",
    },
    {
      label: "俄语",
      value: "Russian",
    },
    {
      label: "塞尔维亚语",
      value: "Serbian",
    },
    {
      label: "斯洛伐克语",
      value: "Slovak",
    },
    {
      label: "斯洛文尼亚语",
      value: "Slovenian",
    },
    {
      label: "西班牙语",
      value: "Spanish",
    },
    {
      label: "瑞典语",
      value: "Swedish",
    },
    {
      label: "泰米尔语",
      value: "Tamil",
    },
    {
      label: "泰语",
      value: "Thai",
    },
    {
      label: "土耳其语",
      value: "Turkish",
    },
    {
      label: "乌克兰语",
      value: "Ukrainian",
    },
    {
      label: "越南语",
      value: "Vietnamese",
    },
  ].map((item) => {
    return {
      ...item,
      example: languagesExampleByLanguage[item.value],
    };
  });
}

export const supportedSEOLanguages = [
  "English",
  "German",
  "Dutch",
  "French",
  "Spanish",
  "Italian",
  "Portuguese",
  "Russian",
  "Polish",
  "Swedish",
  "Hungarian",
  "Indonesian",
  "Arabic",
  "Hebrew",
  "Farsi",
  "Turkish",
  "Norwegian",
  "Czech",
  "Slovak",
  "Greek",
  "Japanese",
];

export const shouldPopulateLanguage = (lang?: string) =>
  lang && lang !== "English";

export { languageOptions };

export const imageRestoreStorageBucket = "user-generated-images";

export const otherUserAssets = "user-other-assets";

export const musicBucket = "user-generated-music";

export const publicBucket = "public";

export const allUserBuckets = [
  imageRestoreStorageBucket,
  otherUserAssets,
  musicBucket,
];

export const constructPublicImageUrl = (path: string) => {
  return `${process.env.NEXT_PUBLIC_SUPABASE_URL}/storage/v1/object/public/${path}`;
};

export const generateEndingWord = "595e1773-16b3";
export const streamJsonSeparator = "19-b3";
export const streamVariationSeparator = "b3-19";
export const pageNumberSeparator = "b3ba";

export const textEnhancementOperation = {
  paraphrase: "paraphrase",
  lengthen: "lengthen",
  translate: "translate",
  shorten: "shorten",
  summarize: "summarize",
  write_outline: "write_outline",
  synonyms: "synonyms",
};

export const interactiveTextOperation: any = {
  expand: "op-expand",
  shorten: "op-shorten",
  paraphrase: "op-paraphrase",
  translate: "op-translate",
  summary: "op-summary",
  writeMore: "op-write-more",
  command: "op-command",
  conclusion: "op-conclusion",
  spellingGrammer: "op-spelling-grammer",
  formatting: "op-formatting",
  changeTone: "op-change-tone",
  simpleLanguage: "op-simple-language",
  addDepth: "op-add-depth",
  addInternalLinks: "op-add-internal-links",
  readability: "op-readability",
  improve: "op-improve",
  metaDescription: "op-meta-description",
  metaTitle: "op-meta-title",
  incopreateKeywords: "op-incopreate-keywords",
  prompt: "op-prompt",
};

export const getLanguagePrompt = (language?: string) => {
  const selectedExampleLanguage =
    language && languagesExampleByLanguage[language]
      ? `(${languagesExampleByLanguage[language]})`
      : "";
  if (!language || !selectedExampleLanguage || language === "English") {
    return "";
  }
  return `${language} language${selectedExampleLanguage}`;
};

export const interactiveTextOperations = Object.values(
  interactiveTextOperation
);

export const delle3Sizes = ["1024x1024", "1024x1792", "1792x1024"];
export const dalle2Sizes = ["256x256", "512x512"];
export const imageSizes = [
  "1200x1200",
  ...delle3Sizes,
  ...dalle2Sizes,
  "768x768",
  // "1300x550",
];

// export const delleSizes = [imageSizes[0], imageSizes[3]];
// export const allSdSizes = [imageSizes[1], imageSizes[2]];
export const allDelleSizes = [imageSizes[0], imageSizes[1], imageSizes[3]];
export const dalleStyles = ["Animal", "Blog"];
export const twoYearInSeconds = 315360000;

export const getHasNegativePrompt = (config: Record<string, any>) => {
  const hasNegativePrompt =
    typeof config.negativePrompt === "string" &&
    config.negativePrompt &&
    config.negativePrompt.length > 0;
  return hasNegativePrompt;
};

export const oneDayInSeconds = 86400;

export enum FromType {
  fromChatFileUpload = "chat-file-upload",
  info = "info-base",
  webPage = "web-page",
}

export enum IntegrationType {
  Webflow = "webflow",
  Medium = "medium",
  WordPress = "wordpress",
}

let defaultCollectionName = "Untitled Collection";
let defaultPostName = "Untitled Post";
let markdownFileName = "content.md";
let custom = "custom";

if (isCn()) {
  custom = "自定义";
  markdownFileName = "内容.md";
  defaultCollectionName = "无名文档";
  defaultPostName = "无名文章";
}

export const getBulkGenerateCost = (config: { size: string }) => {
  if (config.size === "short") {
    return 3000;
  }
  if (config.size === "medium") {
    return 4000;
  }
  return undefined;
};

export const getBulkGenerateCostWorkflows = (config: { size: string }) => {
  if (config.size === "short") {
    return 1;
  }
  if (config.size === "medium") {
    return 1;
  }
  return undefined;
};

export const getBulkGenerateAmount = (config: BulkGenerateJobConfigPayload) => {
  const length =
    config.generationMode === "urls"
      ? config.urls.length
      : config.titles.length;
  return length;
};

export const calculateBulkGenerateCostsWorkflows = (
  config: BulkGenerateJobConfigPayload
) => {
  const length = getBulkGenerateAmount(config);

  const perCost = getBulkGenerateCostWorkflows({ size: config.size });
  if (typeof perCost === "number" && length > 0) {
    return perCost * length;
  }
  return undefined;
};

const calculateBulkGenerateCosts = (config: BulkGenerateJobConfigPayload) => {
  const length = getBulkGenerateAmount(config);

  const perCost = getBulkGenerateCost({ size: config.size });
  if (typeof perCost === "number" && length > 0) {
    return perCost * length;
  }
  return undefined;
};

export const maxBulkGenerateAmount = 50;

const renderAppUrls = {
  dev: "https://junia-ai-dev.onrender.com",
  prod: "https://junia-ai.onrender.com",
};

export const renderAppUrl = renderAppUrls[process.env.NEXT_PUBLIC_ENV_NAME];

export {
  calculateBulkGenerateCosts,
  defaultCollectionName,
  custom,
  markdownFileName,
  defaultPostName,
};
