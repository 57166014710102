declare global {
  interface BigInt {
    toJSON(): string;
  }
}

BigInt.prototype.toJSON = function (): string {
  return this.toString();
};

import "@/styles/globals.css";
import "@/styles/animations.scss";
import "@/styles/markdown.scss";
import "@/styles/markdown-v2.scss";
import "@/styles/markdown-v3.scss";

import React from "react";
import { appWithTranslation } from "next-i18next";
import { DefaultHeadTags } from "common/head-tags";
import { Provider } from "react-redux";
import { wrapper } from "reducers";
import { ThemeProvider } from "@mui/material/styles";
// import CssBaseline from "@mui/material/CssBaseline";
import { lightTheme } from "modules/themes";
import { CacheProvider } from "@emotion/react";

import dynamic from "next/dynamic";
import NonProtectedLayout from "modules/non-protected-page/layout";
// import { Analytics } from "@vercel/analytics/react";
import createEmotionCache from "client-server-shared/emotion-cache";
import { SoftwareAppJsonLd } from "next-seo";
import { siteConfig } from "client-server-shared/config/siteConfig";
import { LazySentryErrorBoundary } from "utils/error-catching/lazy-sentry-error-boundary";
import { ErrorLayoutAbsoluteCenter } from "components/error/error-layout";
import SentryLazyLoader from "utils/error-catching/lazy-sentry-loader";
import { primaryFont } from "modules/themes/base/typography";
import { isCn } from "client-server-shared/constants";

/*
const UserWatcher = dynamic(() => import("modules/user-watcher"), {
  ssr: false,
});
*/

const Toaster = dynamic(() => import("components/ui/toast/toast-container"), {
  ssr: false,
});

/*
const SnackBarContainer = dynamic(
  () => import("components/ui/snackbar/snackbar"),
  {
    ssr: false,
  }
);
*/

const Progressbar = dynamic(() => import("components/progress-bar"), {
  ssr: false,
});

const clientSideEmotionCache = createEmotionCache();

const ScriptRenderer = React.memo(() => {
  return (
    <SoftwareAppJsonLd
      name={siteConfig.name}
      price="0.00"
      priceCurrency="USD"
      operatingSystem="Web"
      applicationCategory="ProductivityApplication"
      applicationSubCategory="AIApplication"
      mainEntityOfPage={siteConfig.url}
      image={`${siteConfig.url}/og.png`}
    />
  );
});

function MyApp({ Component, ...rest }) {
  const {
    store,
    props: { pageProps, emotionCache = clientSideEmotionCache },
  } = wrapper.useWrappedStore(rest);
  return (
    <LazySentryErrorBoundary
      showDialog={false}
      fallback={<ErrorLayoutAbsoluteCenter inMainPage={false} />}
    >
      <CacheProvider value={emotionCache}>
        <style jsx global>{`
          html {
            font-family: ${primaryFont.style.fontFamily} !important;
          }
          .markdown-content {
            font-family: ${primaryFont.style.fontFamily} !important;
          }
        `}</style>
        <ThemeProvider theme={lightTheme}>
          {/*
          <CssBaseline />
          */}
          <Provider store={store}>
            <DefaultHeadTags />
            <NonProtectedLayout>
              <Component {...pageProps} />
              {/* <Analytics />*/}
            </NonProtectedLayout>

            <Toaster />
            <Progressbar />
            {/*
             <SnackBarContainer />
             <UserWatcher />
             <Progressbar />
               <SentryLazyLoader />
            */}
            <ScriptRenderer />
          </Provider>
        </ThemeProvider>
      </CacheProvider>
    </LazySentryErrorBoundary>
  );
}

export default appWithTranslation(MyApp);
