import { Container as MuiContainer } from "components/ui/container";
import { Typography, TypographyProps } from "components/ui/Typography";

export const FeatureSectionText = ({ children }) => {
  return (
    <Typography
      sx={{
        color: "text.white",
        marginBottom: "24px",
      }}
      variant="h2"
      gutterBottom
    >
      {children}
    </Typography>
  );
};

export const headlineSizeConfig = {
  xs: "2.4rem",
  sm: `3rem`,
  md: `3.5rem`,
  xl: `4rem`,
  lg: "4rem",
};

export const Container = ({ children, ...rest }) => {
  return (
    <MuiContainer maxWidth="lg" {...rest}>
      {children}
    </MuiContainer>
  );
};

export type ResponsiveTextProps = {
  decrement?: "small" | "medium" | "large";
} & TypographyProps;

export const ResponsiveText = ({
  children,
  fontSize,
  sx = {},
  decrement = "small",
  ...rest
}: ResponsiveTextProps) => {
  const isSmall = decrement === "small";
  const isLarge = decrement === "large";
  const isMedium = decrement === "medium";

  const xsDecrement = isLarge ? 0.6 : isMedium ? 0.7 : 0.9;
  const smDecrement = isLarge ? 0.6 : isMedium ? 0.7 : 0.9;
  return (
    <Typography
      sx={{
        "& span": {
          fontSize: "inherit",
          fontWeight: "inherit",
          lineHeight: "inherit",
        },
        fontSize: {
          xs: `${fontSize * xsDecrement}px`,
          sm: `${fontSize * smDecrement}px`,
          md: `${fontSize * 0.9}px`,
          lg: `${fontSize}px`,
          xl: `${fontSize}px`,
        },
        ...(sx || {}),
      }}
      {...rest}
    >
      {children}
    </Typography>
  );
};
